/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EPermission = {
    Protected: 'Protected',
    SeeAllPermissions: 'SeeAllPermissions',
    SeeGhostEntities: 'SeeGhostEntities',
    ModuleBoligvaelger: 'ModuleBoligvaelger',
    ModuleSocialMedia: 'ModuleSocialMedia',
    ModuleMediaOrbit: 'ModuleMediaOrbit',
    ModuleProject: 'ModuleProject',
    ModuleProjectMultiCase: 'ModuleProjectMultiCase',
    ModuleSmartText: 'ModuleSmartText',
    TranslationsWrite: 'TranslationsWrite',
    UserRead: 'UserRead',
    UserWrite: 'UserWrite',
    UserDelete: 'UserDelete',
    UserResendWelcomeMail: 'UserResendWelcomeMail',
    UserSimpleSearch: 'UserSimpleSearch',
    ProjectRead: 'ProjectRead',
    ProjectWrite: 'ProjectWrite',
    ProjectDelete: 'ProjectDelete',
    ResolveJwts: 'ResolveJwts',
    GenerateApplicationJwt: 'GenerateApplicationJwt',
    CaseRead: 'CaseRead',
    CaseDelete: 'CaseDelete',
    CaseCreate: 'CaseCreate',
    CaseModifyAddress: 'CaseModifyAddress',
    CaseModifyState: 'CaseModifyState',
    CaseModifyMask: 'CaseModifyMask',
    CaseModifyAssets: 'CaseModifyAssets',
    CaseModifyCustomFields: 'CaseModifyCustomFields',
    FieldRead: 'FieldRead',
    FieldWrite: 'FieldWrite',
    FieldDelete: 'FieldDelete',
    RoleRead: 'RoleRead',
    RoleWrite: 'RoleWrite',
    RoleDelete: 'RoleDelete',
    AuditLogRead: 'AuditLogRead',
    MediaSequenceRead: 'MediaSequenceRead',
    MediaSequenceWrite: 'MediaSequenceWrite',
    MediaSequenceDelete: 'MediaSequenceDelete',
    MediaSequenceResultReadyNotifications: 'MediaSequenceResultReadyNotifications',
    MediaSequenceEditorAssets: 'MediaSequenceEditorAssets',
    MediaSequenceEditorAssetsUploadFile: 'MediaSequenceEditorAssetsUploadFile',
    MediaSequenceEditorAssetsExistingFile: 'MediaSequenceEditorAssetsExistingFile',
    MediaSequenceEditorBranches: 'MediaSequenceEditorBranches',
    MediaSequenceEditorBranchMutations: 'MediaSequenceEditorBranchMutations',
    MediaSequenceEditorTranscription: 'MediaSequenceEditorTranscription',
    MediaSequenceEditorResults: 'MediaSequenceEditorResults',
    MediaSequenceEditorTimeline: 'MediaSequenceEditorTimeline',
    MediaSequenceAssetsReadyNotifications: 'MediaSequenceAssetsReadyNotifications',
    MediaSequenceClone: 'MediaSequenceClone',
    MediaSequenceEditorTranscriptionAdvanced: 'MediaSequenceEditorTranscriptionAdvanced',
    MediaSequenceDeleteNonDraft: 'MediaSequenceDeleteNonDraft',
    MediaSequenceMetaMappings: 'MediaSequenceMetaMappings',
    MediaSequenceGlobalTemplateAdministrator: 'MediaSequenceGlobalTemplateAdministrator',
    AssetWrite: 'AssetWrite',
    AssetDelete: 'AssetDelete',
    OrganizationRead: 'OrganizationRead',
    OrganizationWrite: 'OrganizationWrite',
    OrganizationDelete: 'OrganizationDelete',
    OrganizationSeeSessionStatistics: 'OrganizationSeeSessionStatistics',
    VisualIdentityRead: 'VisualIdentityRead',
    VisualIdentityWrite: 'VisualIdentityWrite',
    VisualIdentityDelete: 'VisualIdentityDelete',
    InterOrganizationalAccess: 'InterOrganizationalAccess',
    BypassOrganizationalRestriction: 'BypassOrganizationalRestriction',
    ServiceTicketRead: 'ServiceTicketRead',
    ServiceTicketWrite: 'ServiceTicketWrite',
    ServiceTicketDelete: 'ServiceTicketDelete',
    ServiceTicketSupporter: 'ServiceTicketSupporter',
    PublicationRead: 'PublicationRead',
    PublicationWrite: 'PublicationWrite',
    PublicationDelete: 'PublicationDelete',
    ExternalIntegrationRead: 'ExternalIntegrationRead',
    ExternalIntegrationWrite: 'ExternalIntegrationWrite',
    ExternalIntegrationDelete: 'ExternalIntegrationDelete',
    DynamicTemplateRead: 'DynamicTemplateRead',
    DynamicTemplateWrite: 'DynamicTemplateWrite',
    DynamicTemplateDelete: 'DynamicTemplateDelete',
    DynamicTemplateGlobalAccess: 'DynamicTemplateGlobalAccess',
    EstateOrbitToggle: 'EstateOrbitToggle',
    EstateOrbitImportFrom3Da: 'EstateOrbitImportFrom3DA',
    EstateOrbitImportFromEsoft: 'EstateOrbitImportFromEsoft',
    EstateOrbitViewInjectionCode: 'EstateOrbitViewInjectionCode',
    TextCollectionRead: 'TextCollectionRead',
    TextCollectionWrite: 'TextCollectionWrite',
    TextCollectionDelete: 'TextCollectionDelete',
    SellingPointRead: 'SellingPointRead',
    SellingPointWrite: 'SellingPointWrite',
    SellingPointDelete: 'SellingPointDelete'
} as const;
export type EPermission = typeof EPermission[keyof typeof EPermission];


export function EPermissionFromJSON(json: any): EPermission {
    return EPermissionFromJSONTyped(json, false);
}

export function EPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EPermission {
    return json as EPermission;
}

export function EPermissionToJSON(value?: EPermission | null): any {
    return value as any;
}

