import {
  AssetSimpleResponse,
  EAssetType,
  EFieldType,
  EFieldVisibility,
  FieldCreateRequest,
  FieldResponse,
  FieldUpdateRequest,
} from 'api/core';
import { useCreateField, useUpdateField } from 'api/useFieldsApi';
import { useGetProject } from 'api/useProjectsApi';
import { AssetUpload } from 'components/Form/AssetUpload';
import { AssetSelectSingleAsync } from 'components/Select/AssetSelectSingleAsync';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  fieldTypeTranslate,
  fieldVisibilityTranslate,
} from 'utils/enum-translate';

interface FieldFormProps {
  targetField?: FieldResponse;
  projectId?: string;
  onSuccess: () => void;
  onCancel: () => void;
  inDialog?: boolean;
}

export const FieldForm = ({
  targetField,
  projectId,
  onSuccess,
  onCancel,
  inDialog,
}: FieldFormProps) => {
  const { data: project } = useGetProject(projectId, false);

  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateField();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateField();

  const illustrationfileInputRef = useRef<HTMLInputElement>(null);

  const showFieldVisibility = project?.estateOrbitEnabled || false;
  const showFieldIsFilter = project?.estateOrbitEnabled || false;
  const showFieldIllustration = project?.estateOrbitEnabled || false;

  const [
    initialSelectedFieldIllustration,
    setInitialSelectedFieldIllustration,
  ] = useState<AssetSimpleResponse | undefined>(targetField?.illustration);

  const { register, handleSubmit, setValue } = useForm<
    FieldCreateRequest | FieldUpdateRequest
  >({
    defaultValues: {
      visibility: EFieldVisibility.AlwaysVisible,
      isFilter: true,
      ...targetField,
    },
  });

  const onSubmit = handleSubmit(async (result) => {
    if (targetField) {
      await updateAsync({
        id: targetField.id,
        fieldUpdateRequest: {
          ...result,
          illustrationId:
            result.illustrationId === '' ? null : result.illustrationId,
        },
      });
    } else {
      if (!projectId) return;
      await createAsync({
        projectId,
        fieldCreateRequest: {
          ...result,
          illustrationId:
            result.illustrationId === '' ? null : result.illustrationId,
        },
      });
    }
    onSuccess();
  });

  const onIllustrationImageSelected = (asset: AssetSimpleResponse | null) => {
    if (!asset) return;
    setValue('illustrationId', asset.id);
    setInitialSelectedFieldIllustration(asset);
  };

  return (
    <>
      <AssetUpload
        fileInputRef={illustrationfileInputRef}
        projectId={projectId}
        onAssetUploaded={onIllustrationImageSelected}
        accept="image/*"
      />
      <form onSubmit={onSubmit} className="space-y-2">
        <div className="form-control">
          <label className="label">
            <span className="label-text">Navn</span>
          </label>
          <input
            {...register('name', { required: true })}
            type="text"
            className="input input-bordered"
          />
        </div>

        {showFieldIllustration ? (
          <div className="form-control">
            <label className="label">
              <span className="label-text">Illustration</span>
            </label>
            <AssetSelectSingleAsync
              // TODO: This should search for icons in lucide-react
              // Only search in project assets
              projectId={projectId}
              types={[EAssetType.Image]}
              onSelected={onIllustrationImageSelected}
              initialValue={initialSelectedFieldIllustration}
              showCreateOption
              onCreateOptionSelected={() =>
                illustrationfileInputRef.current?.click()
              }
              inDialog={inDialog}
            />
            <input
              {...register('illustrationId')}
              type="text"
              className="hidden"
            />
          </div>
        ) : null}

        <div className="form-control">
          <label className="label">
            <span className="label-text">Type</span>
          </label>
          <select
            {...register('type', { required: true })}
            className="select select-bordered"
          >
            {[
              EFieldType.Rent,
              EFieldType.Currency,
              EFieldType.Number,
              EFieldType.Area,
              EFieldType.SubsidyArea,
              EFieldType.Date,
              EFieldType.Boolean,
              EFieldType.HousingType,
              EFieldType.Text,
            ].map((e) => (
              <option key={e} value={e}>
                {fieldTypeTranslate(e)}
              </option>
            ))}
          </select>
        </div>

        {showFieldVisibility ? (
          <div className="form-control">
            <label className="label">
              <span className="label-text">Synlighed</span>
            </label>
            <select
              {...register('visibility', { required: true })}
              className="select select-bordered"
            >
              {Object.values(EFieldVisibility).map((e) => (
                <option key={e} value={e}>
                  {fieldVisibilityTranslate(e)}
                </option>
              ))}
            </select>
          </div>
        ) : null}

        {showFieldIsFilter ? (
          <div className="form-control items-start">
            <label className="cursor-pointer label">
              <span className="label-text me-2">Filtrerbar</span>
              <input
                {...register('isFilter')}
                type="checkbox"
                className="toggle toggle-primary"
              />
            </label>
          </div>
        ) : null}

        <div className="form-control">
          <label className="label">
            <span className="label-text">Rækkefølge</span>
          </label>
          <input
            {...register('order', {
              required: true,
            })}
            type="number"
            min={0}
            className="input input-bordered"
          />
        </div>

        <div className="flex justify-center space-x-4 pt-4">
          <button
            className="btn btn-primary"
            disabled={isPendingCreate || isPendingUpdate}
          >
            {targetField ? 'Opdater' : 'Opret'}
          </button>
          {onCancel ? (
            <button type="button" className="btn" onClick={onCancel}>
              Annuller
            </button>
          ) : null}
        </div>
      </form>
    </>
  );
};
