import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useGetMediaSequences } from 'api/useMediaSequencesApi';
import { MediaSequencesTable } from './components/MediaSequence/MediaSequencesTable';
import { MediaSequenceCreate } from './components/MediaSequence/MediaSequenceCreate';
import { useState } from 'react';
import { useIsMobile } from 'utils/useIsMobile';

export const MediaSequences = () => {
  const [showArchived, setShowArchived] = useState(false);

  return (
    <Page
      title="Smart Video - Videoer"
      breadcrumbs={[{ name: 'Smart Video' }, { name: 'Videoer' }]}
    >
      <div className="flex justify-start py-2 pb-3 w-full">
        <MediaSequenceCreate
          trigger={<button className="btn btn-primary">Opret video</button>}
        />
        <div className="ml-auto">
          <label className="cursor-pointer label">
            <span className="label-text me-2">Vis arkiverede</span>
            <input
              type="checkbox"
              id="showArchived"
              name="showArchived"
              checked={showArchived}
              onChange={() => setShowArchived(!showArchived)}
              className="toggle toggle-primary"
            />
          </label>
        </div>
      </div>
      <TableWrapper
        includeActive={!showArchived}
        includeArchived={showArchived}
      />
    </Page>
  );
};

interface TableWrapperProps {
  includeActive: boolean;
  includeArchived: boolean;
}

const TableWrapper = ({
  includeActive,
  includeArchived,
}: TableWrapperProps) => {
  const isMobile = useIsMobile();
  const { state } = useTableQueryState();

  const expectedFilter = JSON.stringify({
    includeActive: includeActive,
    includeArchived: includeArchived,
  });

  if (state.customState != expectedFilter) {
    state.setCustomState(expectedFilter);
  }

  const { data, isFetching: isFetchingFields } = useGetMediaSequences(state, {
    refetchInterval: isMobile ? undefined : 5000,
  });

  return (
    <MediaSequencesTable
      data={data}
      isFetching={isFetchingFields}
      queryState={state}
    />
  );
};
