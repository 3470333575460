import { useMutation, useQuery } from '@tanstack/react-query';

import { queryClient } from 'App';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import {
  MediaSequenceBranchCreateRequest,
  MediaSequenceBranchesApi,
  MediaSequenceBranchMutationCreateRequest,
  MediaSequenceBranchMutationUpdateRequest,
  MediaSequenceBranchUpdateRequest,
} from './core';
import { MediaSequenceApiKeys } from './useMediaSequencesApi';

export const MediaSequenceBranchesApiKeys = {
  GET_MEDIA_SEQUENCE_BRANCH: 'media-sequence-branch',
  GET_MEDIA_SEQUENCE_BRANCH_MUTATION: 'media-sequence-branch-mutation',
};

const client = new MediaSequenceBranchesApi(new CoreBaseConfiguration());

export const useGetMediaSequenceBranch = (id?: string) => {
  return useQuery({
    queryKey: [MediaSequenceBranchesApiKeys.GET_MEDIA_SEQUENCE_BRANCH, id],
    queryFn: () => client.mediaSequenceBranchesBranchIdGet({ id: id ?? '' }),
    enabled: id !== undefined,
  });
};

export const useCreateMediaSequenceBranch = () => {
  return useMutation({
    mutationFn: ({
      mediaSequenceId,
      mediaSequenceBranchCreateRequest,
    }: {
      mediaSequenceId: string;
      mediaSequenceBranchCreateRequest: MediaSequenceBranchCreateRequest;
    }) =>
      client.mediaSequenceBranchesMediaSequenceIdBranchPost({
        mediaSequenceId,
        mediaSequenceBranchCreateRequest,
      }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE, e.mediaSequenceId],
      });
      queryClient.invalidateQueries({
        queryKey: [
          MediaSequenceBranchesApiKeys.GET_MEDIA_SEQUENCE_BRANCH,
          e.id,
        ],
      });
      toast.success(`Formatet er blevet tilføjet til videoen`, {
        toastId: 'createMediaSequenceBranch',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke oprette formatet på videoen');
    },
  });
};

export const useUpdateMediaSequenceBranch = () => {
  return useMutation({
    mutationFn: ({
      id,
      mediaSequenceBranchUpdateRequest,
    }: {
      id: string;
      mediaSequenceBranchUpdateRequest: MediaSequenceBranchUpdateRequest;
    }) =>
      client.mediaSequenceBranchesBranchIdPut({
        id,
        mediaSequenceBranchUpdateRequest,
      }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE, e.mediaSequenceId],
      });
      queryClient.invalidateQueries({
        queryKey: [
          MediaSequenceBranchesApiKeys.GET_MEDIA_SEQUENCE_BRANCH,
          e.id,
        ],
      });
      toast.success(`Formatet på videoen er blevet opdateret`, {
        toastId: 'updateMediaSequenceBranch',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke opdatere formatet på videoen');
    },
  });
};

export const useDeleteMediaSequenceBranch = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.mediaSequenceBranchesBranchIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE, e.mediaSequenceId],
      });
      queryClient.invalidateQueries({
        queryKey: [
          MediaSequenceBranchesApiKeys.GET_MEDIA_SEQUENCE_BRANCH,
          e.id,
        ],
      });
      toast.success(`Formatet på videoen er blevet slettet`, {
        toastId: 'deleteMediaSequenceBranch',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke slette formatet på videoen');
    },
  });
};

export const useGetMediaSequenceBranchMutation = (id?: string) => {
  return useQuery({
    queryKey: [
      MediaSequenceBranchesApiKeys.GET_MEDIA_SEQUENCE_BRANCH_MUTATION,
      id,
    ],
    queryFn: () =>
      client.mediaSequenceBranchesBranchMutationIdGet({ id: id ?? '' }),
    enabled: id !== undefined,
  });
};

export const useCreateMediaSequenceBranchMutation = () => {
  return useMutation({
    mutationFn: ({
      id,
      mediaSequenceBranchMutationCreateRequest,
    }: {
      id: string;
      mediaSequenceBranchMutationCreateRequest: MediaSequenceBranchMutationCreateRequest;
    }) =>
      client.mediaSequenceBranchesIdBranchMutationPost({
        id,
        mediaSequenceBranchMutationCreateRequest,
      }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE, e.mediaSequenceId],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE, e.mediaSequenceId],
      });
      queryClient.invalidateQueries({
        queryKey: [
          MediaSequenceBranchesApiKeys.GET_MEDIA_SEQUENCE_BRANCH_MUTATION,
        ],
      });
      toast.success(`Effekten er blevet tilføjet`, {
        toastId: 'createMediaSequenceBranchMutation',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke tilføje effekten');
    },
  });
};

export const useUpdateMediaSequenceBranchMutation = () => {
  return useMutation({
    mutationFn: ({
      id,
      mediaSequenceBranchMutationUpdateRequest,
    }: {
      id: string;
      mediaSequenceBranchMutationUpdateRequest: MediaSequenceBranchMutationUpdateRequest;
    }) =>
      client.mediaSequenceBranchesBranchMutationIdPut({
        id,
        mediaSequenceBranchMutationUpdateRequest,
      }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE, e.mediaSequenceId],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE, e.mediaSequenceId],
      });
      queryClient.invalidateQueries({
        queryKey: [
          MediaSequenceBranchesApiKeys.GET_MEDIA_SEQUENCE_BRANCH_MUTATION,
          e.id,
        ],
      });
      toast.success(`Effekten er blevet opdateret`, {
        toastId: 'updateMediaSequenceBranchMutation',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke opdatere effekten');
    },
  });
};

export const useDeleteMediaSequenceBranchMutation = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.mediaSequenceBranchesBranchMutationIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE, e.mediaSequenceId],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceApiKeys.GET_MEDIA_SEQUENCE, e.mediaSequenceId],
      });
      queryClient.invalidateQueries({
        queryKey: [
          MediaSequenceBranchesApiKeys.GET_MEDIA_SEQUENCE_BRANCH_MUTATION,
          e.id,
        ],
      });
      toast.success(`Effekten er blevet slettet`, {
        toastId: 'deleteMediaSequenceBranchMutation',
      });
    },
    onError: () => {
      toast.warn('Kunne ikke slette effekten');
    },
  });
};
