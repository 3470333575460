import { ETranscriptionState, MediaSequenceResponse } from 'api/core';
import {
  useGetMediaSequence,
  useInvokeMediaSequence,
  useRedoMediaSequence,
} from 'api/useMediaSequencesApi';
import { MutateEntity } from 'components/Form/MutateEntity';
import { MediaSequenceTranscriptionForm } from 'pages/MediaOrbit/components/MediaSequence/Transcription/MediaSequenceTranscriptionForm';
import { useCallback, useEffect, useState } from 'react';
import { useMediaSequenceSetup } from './MediaSequenceSetupProvider';
import { MediaSequenceProgress } from './MediaSequenceProgress';

interface MediaSequenceSetupTranscriptionProps {
  mediaSequence: MediaSequenceResponse;
  disabled: boolean;
}

export const MediaSequenceSetupTranscription = ({
  mediaSequence,
  disabled,
}: MediaSequenceSetupTranscriptionProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { data } = useGetMediaSequence(mediaSequence.id, {
    refetchInterval:
      mediaSequence.transcriptionState === ETranscriptionState.Pending
        ? 5000
        : undefined,
  });
  if (data?.transcription)
    data.transcription = data.transcription
      .replaceAll('\r\n', '\n')
      .replaceAll('\r', '\n');

  const { handlePrevious, handleNext } = useMediaSequenceSetup();

  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableText, setDisableText] = useState<string>('');

  const onRevalidateStep = useCallback(() => {
    if (mediaSequence.transcriptionState !== ETranscriptionState.Approved) {
      setDisableNext(true);
      if (
        mediaSequence.transcriptionState === ETranscriptionState.NotApplicable
      ) {
        setDisableText('Lav undertekster for at forsætte');
      } else {
        setDisableText('Godkend underteksterne for at fortsætte');
      }
    } else {
      setDisableNext(false);
      setDisableText('');
    }
  }, [mediaSequence.transcriptionState]);

  useEffect(() => {
    onRevalidateStep();
  }, [mediaSequence, onRevalidateStep]);

  if (!data) return null;

  return (
    <>
      {/* First step: Start flow */}
      {data.transcriptionState === ETranscriptionState.NotApplicable ? (
        <div className="space-y-4">
          <p>
            Når du trykker på knappen nedenfor vil Smart Video starte med at
            lave sammenklippe din video så der kan blive lavet undertekster til
            den. Det betyder at du ikke kan ændre i videoen efterfølgende, så
            sørg for at alt er som det skal være før du trykker på knappen.
          </p>
          <p>
            De undertekster der bliver lavet er et udkast, hvor du kan redigere
            og efterfølgende godkende dem.
          </p>
          <MutateEntity
            trigger={
              <button
                id="subtitles-generate-subtitles"
                className="btn btn-primary"
                disabled={isLoading}
              >
                Lav undertekster
              </button>
            }
            title={`Start ${data.name} video?`}
            description={`Er du sikker påbegynde redigering af videoen '${data.name}'? Du kan ikke ændre i den efterfølgende.`}
            setter={useInvokeMediaSequence}
            mutationVariables={{ id: data.id }}
            onMutate={() => setIsLoading(false)}
            onMutateConfirm={() => setIsLoading(true)}
            hidden={disabled}
          />
        </div>
      ) : null}
      {/* Second step: Wait for transcription */}
      {data.transcriptionState === ETranscriptionState.Pending ? (
        <MediaSequenceProgress mediaSequence={mediaSequence} />
      ) : null}
      {/* Third step: Edit and approve */}
      {data.transcriptionState === ETranscriptionState.Approved ||
      data.transcriptionState === ETranscriptionState.Generated ? (
        <div className="space-y-4">
          <p>
            Underteksterne er nu blevet lavet af Smart Video, og du kan ændre i
            dem. Når du er tilfreds med resultatet, kan du godkende dem, og de
            vil blive låst.
          </p>
          {data.transcription === '' ? (
            <p>
              Resultatet er tomt. Der er ingen undertekster at redigere eller
              godkende. Hvis der skulle have været undertekster, så lav en kopi
              af videoen og prøv igen.
            </p>
          ) : null}
          {/* TODO: Maybe a permission? */}
          <div className="space-y-2">
            <p>
              Hvis du ikke er tilfreds med den video eller undertekster der er
              blevet lavet, så kan du låse op for videoen og lave de nødvendige
              ændringer eller køre processen igen.
            </p>
            <MutateEntity
              trigger={
                <button type="button" className="btn btn-warning">
                  Lav video om
                </button>
              }
              title={`Lav videoen ${mediaSequence.name} om?`}
              description={`Er du sikker på du vil lave videoen '${mediaSequence.name}' om? Din opsætning vil blive gemt men resultaterne vil blive slettet.`}
              setter={useRedoMediaSequence}
              mutationVariables={{ id: mediaSequence.id }}
            />
          </div>
          <MediaSequenceTranscriptionForm
            mediaSequence={data}
            disabled={disabled}
          />
        </div>
      ) : null}

      <div className="flex flex-col mt-4 mb-8">
        <div id="step-navigation" className="join justify-center mt-2">
          <button className="join-item btn" onClick={handlePrevious}>
            Forrige trin
          </button>
          <button
            className="join-item btn"
            onClick={handleNext}
            disabled={disableNext}
          >
            Næste trin
          </button>
        </div>
        {disableNext && disableText ? (
          <div className="text-center mt-2">
            <p className="text-sm text-gray-500">{disableText}</p>
          </div>
        ) : null}
      </div>
    </>
  );
};
