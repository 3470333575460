/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TextCollectionCreateRequest,
  TextCollectionResponse,
  TextCollectionResponsePagedData,
  TextCollectionWithAiCreateRequest,
  TextEntryUpsertRequest,
} from '../models';
import {
    TextCollectionCreateRequestFromJSON,
    TextCollectionCreateRequestToJSON,
    TextCollectionResponseFromJSON,
    TextCollectionResponseToJSON,
    TextCollectionResponsePagedDataFromJSON,
    TextCollectionResponsePagedDataToJSON,
    TextCollectionWithAiCreateRequestFromJSON,
    TextCollectionWithAiCreateRequestToJSON,
    TextEntryUpsertRequestFromJSON,
    TextEntryUpsertRequestToJSON,
} from '../models';

export interface TextCollectionsCreateUsingAiPostRequest {
    textCollectionWithAiCreateRequest?: TextCollectionWithAiCreateRequest;
}

export interface TextCollectionsGetRequest {
    caseId?: string;
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
}

export interface TextCollectionsIdDeleteRequest {
    id: string;
}

export interface TextCollectionsIdGetRequest {
    id: string;
}

export interface TextCollectionsPostRequest {
    textCollectionCreateRequest?: TextCollectionCreateRequest;
}

export interface TextCollectionsPutRequest {
    textEntryUpsertRequest?: TextEntryUpsertRequest;
}

/**
 * 
 */
export class TextCollectionsApi extends runtime.BaseAPI {

    /**
     */
    async textCollectionsCreateUsingAiPostRaw(requestParameters: TextCollectionsCreateUsingAiPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TextCollectionResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/TextCollections/create-using-ai`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TextCollectionWithAiCreateRequestToJSON(requestParameters.textCollectionWithAiCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TextCollectionResponseFromJSON(jsonValue));
    }

    /**
     */
    async textCollectionsCreateUsingAiPost(requestParameters: TextCollectionsCreateUsingAiPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TextCollectionResponse> {
        const response = await this.textCollectionsCreateUsingAiPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async textCollectionsGetRaw(requestParameters: TextCollectionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TextCollectionResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.caseId !== undefined) {
            queryParameters['caseId'] = requestParameters.caseId;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/TextCollections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TextCollectionResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async textCollectionsGet(requestParameters: TextCollectionsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TextCollectionResponsePagedData> {
        const response = await this.textCollectionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async textCollectionsIdDeleteRaw(requestParameters: TextCollectionsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TextCollectionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling textCollectionsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/TextCollections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TextCollectionResponseFromJSON(jsonValue));
    }

    /**
     */
    async textCollectionsIdDelete(requestParameters: TextCollectionsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TextCollectionResponse> {
        const response = await this.textCollectionsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async textCollectionsIdGetRaw(requestParameters: TextCollectionsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TextCollectionResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling textCollectionsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/TextCollections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TextCollectionResponseFromJSON(jsonValue));
    }

    /**
     */
    async textCollectionsIdGet(requestParameters: TextCollectionsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TextCollectionResponse> {
        const response = await this.textCollectionsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async textCollectionsPostRaw(requestParameters: TextCollectionsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TextCollectionResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/TextCollections`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TextCollectionCreateRequestToJSON(requestParameters.textCollectionCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TextCollectionResponseFromJSON(jsonValue));
    }

    /**
     */
    async textCollectionsPost(requestParameters: TextCollectionsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TextCollectionResponse> {
        const response = await this.textCollectionsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async textCollectionsPutRaw(requestParameters: TextCollectionsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TextCollectionResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/TextCollections`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TextEntryUpsertRequestToJSON(requestParameters.textEntryUpsertRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TextCollectionResponseFromJSON(jsonValue));
    }

    /**
     */
    async textCollectionsPut(requestParameters: TextCollectionsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TextCollectionResponse> {
        const response = await this.textCollectionsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
