import { Page } from 'layouts/Admin/Page';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { EPermission } from 'api/core';
import { SmartTextCreate } from './SmartTextCreate';
import { SmartTextsTable } from './SmartTextsTable';
import { useTableQueryState } from 'components/Table/useTableQueryState';
import { useGetTextCollections } from 'api/useTextCollectionsApi';

export const SmartTexts = () => {
  const { state } = useTableQueryState();
  const { data, isFetching } = useGetTextCollections(state);

  return (
    <Page
      title="Smart Text - Oversigt"
      breadcrumbs={[{ name: 'Smart Text' }, { name: 'Oversigt' }]}
    >
      <div className="flex justify-between my-4">
        <PermissionProtectedComponent
          permissions={[EPermission.TextCollectionWrite]}
        >
          <SmartTextCreate
            trigger={
              <div className="flex justify-start w-full">
                <button className="btn btn-primary mr-2">
                  Opret boligtekst
                </button>
              </div>
            }
          />
        </PermissionProtectedComponent>
      </div>

      <SmartTextsTable data={data} isFetching={isFetching} queryState={state} />
    </Page>
  );
};
