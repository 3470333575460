import { useGetAssets } from 'api/useAssetsApi';
import { useGetProject } from 'api/useProjectsApi';
import { Page } from 'layouts/Admin/Page';
import { useParams } from 'react-router-dom';
import { useTableQueryState } from 'components/Table/useTableQueryState';
import { AssetsTable } from './components/Asset/AssetsTable';
import { useRef } from 'react';
import { AssetUpload } from 'components/Form/AssetUpload';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { EPermission } from 'api/core';
import { useAuth } from 'auth/AuthProvider';

export const ProjectAssets = () => {
  const { id } = useParams<{ id: string }>();
  const { state } = useTableQueryState();
  const { hasPermissions } = useAuth();
  const { data: project } = useGetProject(id, true);
  const { data: assets, isFetching: isFetchingAssets } = useGetAssets(
    id,
    state
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <Page
      title={project?.name}
      breadcrumbs={[
        {
          name: hasPermissions([EPermission.ModuleProjectMultiCase])
            ? 'Projekter'
            : 'Sager',
        },
        { name: project?.name ?? '' },
        { name: 'Filer' },
      ]}
    >
      <PermissionProtectedComponent permissions={[EPermission.AssetWrite]}>
        <div className="flex justify-start py-2 w-full">
          <AssetUpload
            fileInputRef={fileInputRef}
            projectId={id as string}
            allowMultiple
          />
          <button
            className="btn btn-primary"
            onClick={() => fileInputRef.current?.click()}
          >
            Upload fil
          </button>
        </div>
      </PermissionProtectedComponent>
      <AssetsTable
        data={assets}
        isFetching={isFetchingAssets}
        queryState={state}
      />
    </Page>
  );
};
