import {
  CaseResponse,
  EConcatenationMode,
  EPermission,
  MediaSequenceResponse,
  MediaSequenceUpdateRequest,
  UserSimpleResponse,
} from 'api/core';
import { useUpdateMediaSequence } from 'api/useMediaSequencesApi';
import { useSearchUsers } from 'api/useUsersApi';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { LabelWithHelperText } from 'components/Form/LabelWithHelperText';
import { EntitySelectSingleAsync } from 'components/Select/EntitySelectSingleAsync';
import { CaseUpdate } from 'pages/Project/components/Case/CaseUpdate';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { mediaSequenceStateTranslate } from 'utils/enum-translate';

interface MediaSequenceUpdateFormProps {
  mediaSequence: MediaSequenceResponse;
  onSuccess?: () => void;
  onCancel?: () => void;
  disabled?: boolean;
}

export const MediaSequenceUpdateForm = ({
  mediaSequence,
  onSuccess,
  onCancel,
  disabled,
}: MediaSequenceUpdateFormProps) => {
  const [caseEditTarget, setCaseEditTarget] = useState<CaseResponse | null>(
    null
  );
  const { mutateAsync, isPending } = useUpdateMediaSequence();

  const { register, handleSubmit, setValue } =
    useForm<MediaSequenceUpdateRequest>({
      defaultValues: mediaSequence,
      disabled,
    });

  const onSubmit = handleSubmit(async (result) => {
    await mutateAsync({
      id: mediaSequence.id,
      mediaSequenceUpdateRequest: result,
    });
    onSuccess?.();
  });

  const onUserSelected = (userResponse: UserSimpleResponse | null) => {
    setValue('metaMappingUserId', userResponse?.id);
  };

  return (
    <>
      <form className="flex flex-wrap" onSubmit={onSubmit}>
        <div className="space-y-2 w-full">
          <PermissionProtectedComponent
            permissions={[EPermission.UserSimpleSearch]}
          >
            <div className="form-control">
              <LabelWithHelperText
                label="Tilknyt mægler"
                helperText="Der bliver automatisk indhentet stamdata for den valgte mægler."
              />
              <EntitySelectSingleAsync<
                UserSimpleResponse,
                { searchTerm?: string }
              >
                useSearchFunction={useSearchUsers}
                searchFunctionOrder={(a, b) => a.name.localeCompare(b.name)}
                searchParams={{}}
                renderSuggestion={(userSuggestion) => (
                  <>
                    {userSuggestion.name}{' '}
                    {userSuggestion.title
                      ? `(${userSuggestion.title})`
                      : undefined}
                  </>
                )}
                onSuggestionSelected={onUserSelected}
                initialValue={mediaSequence.metaMappingUser}
                disabled={disabled}
              />
              {/* TODO: Add endpoint to check what fields will be lost if they acutally change */}
              <div className="label">
                <p className="text-sm">
                  Vær opmærksom på at hvis du ændre en tilknyttet mægler, så vil
                  de foruddefinerede værdier på dine dynamiske overlejringer
                  tage udgangspunkt i den nye mægler.
                </p>
              </div>
            </div>
          </PermissionProtectedComponent>

          <PermissionProtectedComponent
            permissions={[EPermission.MediaSequenceEditorBranchMutations]}
          >
            <div className="form-control">
              <label className="label">
                <span className="label-text">Overgangsmodus</span>
              </label>
              <select
                {...register('concatenationMode', { required: true })}
                className="select select-bordered"
              >
                <option value={EConcatenationMode.Wipe}>Wipe</option>
                <option value={EConcatenationMode.Fade}>Fade</option>
                <option value={EConcatenationMode.Merge}>
                  Ingen animation
                </option>
              </select>
            </div>
            {/* TODO */}
            {/* <VideoConcatenationModePreview mode={watch('concatenationMode')} /> */}
          </PermissionProtectedComponent>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Adresse</span>
            </label>
            <input
              disabled
              className="input input-bordered w-full"
              placeholder={mediaSequence.name}
            />
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Status</span>
            </label>
            <input
              className="input input-bordered join-item w-full"
              disabled
              placeholder={mediaSequenceStateTranslate(mediaSequence.state)}
            />
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Oprettet af</span>
            </label>
            <input
              className="input input-bordered join-item w-full"
              disabled
              placeholder={mediaSequence.user?.name}
            />
          </div>
        </div>

        {disabled ? null : (
          <div className="flex justify-center space-x-4 pt-4 w-full">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isPending}
            >
              Opdater
            </button>
            {onCancel ? (
              <button type="button" className="btn" onClick={onCancel}>
                Annuller
              </button>
            ) : null}
          </div>
        )}
      </form>
      {caseEditTarget ? (
        <CaseUpdate
          id={caseEditTarget.id}
          projectId={caseEditTarget.project.id}
          isInitialOpen={true}
          onClosed={() => setCaseEditTarget(null)}
        />
      ) : null}
    </>
  );
};
