import {
  FormatDistanceStrictOptions,
  formatDistanceToNowStrict,
} from 'date-fns';
import { format } from 'date-fns/fp';
import { da } from 'date-fns/locale/da';

export const formatDate = format('yyyy-MM-dd');

export const formatTimestamp = format('yyyy-MM-dd HH:mm:ss');

export const formatDistanceToNow = (
  date: Date,
  options?: FormatDistanceStrictOptions
) => {
  return formatDistanceToNowStrict(date, {
    locale: da,
    addSuffix: true,
    ...options,
  });
};
