export const calculateChecksumAsync = async (file: File | Blob) => {
  const buffer = await file.arrayBuffer();
  const digest = await crypto.subtle.digest('SHA-256', buffer);
  return hexString(digest);
};

export const getVideoDurationAsync = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    if (!file.type.startsWith('video/')) {
      reject('Invalid file type');
      return;
    }

    const video = document.createElement('video');
    const url = URL.createObjectURL(file);

    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      URL.revokeObjectURL(url);
      resolve(video.duration);
      video.remove();
    };

    video.onerror = (error) => {
      URL.revokeObjectURL(url);
      video.remove();
      reject('Error loading video metadata: ' + error);
    };

    video.src = url;
  });
};

export const getBlobFromUrlAsync = (url: string): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      mode: 'cors',
      credentials: 'omit',
    })
      .then((response) => {
        if (!response.ok) {
          reject('Failed to fetch the resource');
          return;
        }
        return response.blob();
      })
      .then((blob) => {
        if (!blob) {
          reject('Failed to fetch the resource');
          return;
        }
        resolve(blob);
      })
      .catch((error) => {
        reject('Error fetching the resource: ' + error.message);
      });
  });
};

export const getAudioDurationAsync = (url: string): Promise<number> => {
  return new Promise((resolve, reject) => {
    getBlobFromUrlAsync(url)
      .then((blob) => {
        const audio = new Audio();
        audio.src = URL.createObjectURL(blob);
        audio.preload = 'metadata';
        audio.muted = true;

        const handleDuration = () => {
          // If the duration is still infinity after seeking, reject
          if (audio.duration === Infinity) {
            reject('Audio duration is still infinity after seeking');
          } else {
            resolve(audio.duration);
          }
          URL.revokeObjectURL(audio.src); // Cleanup the object URL
        };

        audio.addEventListener('loadedmetadata', () => {
          if (audio.duration === Infinity) {
            // Seek to the end to get the real duration
            audio.currentTime = Number.MAX_SAFE_INTEGER;
            audio.addEventListener('timeupdate', handleDuration, {
              once: true,
            });
          } else {
            resolve(audio.duration);
          }
        });

        audio.addEventListener('error', (error) => {
          reject('Error loading audio metadata: ' + error);
        });
      })
      .catch((error) => {
        reject('Error fetching audio: ' + error);
      });
  });
};

const hexString = (buffer: ArrayBuffer) => {
  const byteArray = new Uint8Array(buffer);
  const hexCodes = [...byteArray].map((value) => {
    return value.toString(16).padStart(2, '0');
  });
  return hexCodes.join('');
};

export const clearWaveform = (canvas: HTMLCanvasElement) => {
  if (!canvas) return;
  const canvasCtx = canvas.getContext('2d');
  if (!canvasCtx) return;
  const { width, height } = canvas;
  canvasCtx.clearRect(0, 0, width, height);
};

export const drawWaveformAsync = async (
  audioBlob: Blob,
  canvas: HTMLCanvasElement
) => {
  const audioContext = new AudioContext();
  const arrayBuffer = await audioBlob.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  if (!canvas) return;
  const canvasCtx = canvas.getContext('2d');
  if (!canvasCtx) return;

  const { width, height } = canvas;
  canvasCtx.clearRect(0, 0, width, height);

  const bufferLength = audioBuffer.length;
  const dataArray = audioBuffer.getChannelData(0);

  canvasCtx.fillStyle = 'rgb(200, 200, 200)';
  canvasCtx.fillRect(0, 0, width, height);

  canvasCtx.lineWidth = 2;
  canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

  canvasCtx.beginPath();

  const sliceWidth = (width * 1.0) / bufferLength;
  let x = 0;

  for (let i = 0; i < bufferLength; i++) {
    const v = dataArray[i] * 0.5;
    const y = height / 2 + v * height;

    if (i === 0) {
      canvasCtx.moveTo(x, y);
    } else {
      canvasCtx.lineTo(x, y);
    }

    x += sliceWidth;
  }

  canvasCtx.lineTo(canvas.width, canvas.height / 2);
  canvasCtx.stroke();
};

export const getDrawRealTimeWaveformFn = (
  stream: MediaStream,
  canvasRef: React.RefObject<HTMLCanvasElement>
) => {
  const audioContext = new AudioContext();
  const source = audioContext.createMediaStreamSource(stream);
  const analyser = audioContext.createAnalyser();
  analyser.fftSize = 2048;
  source.connect(analyser);

  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);

  const drawRealTimeWaveform = () => {
    analyser.getByteTimeDomainData(dataArray);

    const canvas = canvasRef.current;

    if (!canvas) return;
    const canvasCtx = canvas.getContext('2d');
    if (!canvasCtx) return;

    const { width, height } = canvas;
    canvasCtx.clearRect(0, 0, width, height);

    canvasCtx.fillStyle = 'rgb(200, 200, 200)';
    canvasCtx.fillRect(0, 0, width, height);

    canvasCtx.lineWidth = 2;
    canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

    canvasCtx.beginPath();

    const sliceWidth = (width * 1.0) / bufferLength;
    let x = 0;

    for (let i = 0; i < bufferLength; i++) {
      const v = dataArray[i] / 128.0;
      const y = (v * height) / 2;

      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }

      x += sliceWidth;
    }

    canvasCtx.lineTo(canvas.width, canvas.height / 2);
    canvasCtx.stroke();
  };

  return drawRealTimeWaveform;
};
