import { ColumnDef } from '@tanstack/react-table';
import {
  EPermission,
  ServiceTicketResponse,
  ServiceTicketResponsePagedData,
} from 'api/core';
import { CustomTable } from 'components/Table/CustomTable';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import { DateTimeCell } from 'components/Table/Cell/DateTimeCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { useMemo, useState } from 'react';
import { DeleteEntityById } from 'components/Form/DeleteEntityById';
import { LinkCell } from 'components/Table/Cell/LinkCell';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { serviceTicketStateTranslate } from 'utils/enum-translate';
import { useDeleteTicket, useGetTicket } from 'api/useServiceTicketApi';
import { useAuth } from 'auth/AuthProvider';
import { BadgeCell } from 'components/Table/Cell/BadgeCell';

interface TableProps {
  data?: ServiceTicketResponsePagedData;
  isFetching: boolean;
  queryState: TableQueryState;
  showOrganizationColumn?: boolean;
}

export const ServiceTicketsTable = ({
  data,
  isFetching,
  queryState,
  showOrganizationColumn = false,
}: TableProps) => {
  const [deleteTarget, setDeleteTarget] =
    useState<ServiceTicketResponse | null>(null);

  const { hasPermissions } = useAuth();

  const columns = useMemo(() => {
    const baseColumns: (ColumnDef<ServiceTicketResponse> | null)[] = [
      {
        accessorKey: '_view',
        header: 'Vis',
        enableSorting: false,
        cell: (e) => (
          <LinkCell
            icon={<AnimatedIcon icon="open-icon" className="h-6 w-6" />}
            path={`/organization/service-tickets/${e.row.original.id}`}
          />
        ),
      },
      {
        accessorKey: 'title',
        header: 'Emne',
      },
      {
        accessorKey: 'state',
        header: 'Status',
        cell: (e) => (
          <BadgeCell text={serviceTicketStateTranslate(e.row.original.state)} />
        ),
      },
      !showOrganizationColumn
        ? null
        : {
            accessorKey: 'organization.name',
            header: 'Organisation',
          },
      {
        accessorKey: 'lastModifiedUtc',
        header: 'Sidst redigeret',
        cell: (e) => <DateTimeCell value={e.row.original.lastModifiedUtc} />,
      },
      {
        accessorKey: 'createdUtc',
        header: 'Oprettet',
        cell: (e) => <DateTimeCell value={e.row.original.createdUtc} />,
      },
    ];

    if (hasPermissions([EPermission.ServiceTicketDelete])) {
      baseColumns.push({
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) => (
          <ActionCell
            permissions={[EPermission.ServiceTicketDelete]}
            icon={<AnimatedIcon icon="trash-icon" className="h-6 w-6" />}
            onClick={() => setDeleteTarget(e.row.original)}
          />
        ),
      });
    }

    return baseColumns.filter(
      (x) => x !== null
    ) as ColumnDef<ServiceTicketResponse>[];
  }, [setDeleteTarget, hasPermissions, showOrganizationColumn]);

  const table = useGetCustomReactTable(
    data?.data ?? [],
    data?.page.totalPages ?? -1,
    columns,
    queryState
  );

  return (
    <>
      <CustomTable table={table} isLoading={isFetching} />
      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget?.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          titlePropertyKey={'title'}
          getter={useGetTicket}
          deleter={useDeleteTicket}
        />
      ) : null}
    </>
  );
};
