import {
  AssetResponse,
  CaseCreateRequest,
  CaseResponse,
  CaseUpdateRequest,
  ECaseState,
  EFieldType,
  EPermission,
  FieldResponse,
} from 'api/core';
import { useCreateCase, useUpdateCase } from 'api/useCasesApi';
import { useAuth } from 'auth/AuthProvider';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import {
  AddressSuggestion,
  AutocompleteAddress,
} from 'components/Form/AutocompleteAddress';
import { EntitySelectMultiple } from 'components/Select/EntitySelectMultiple';
import { AssetPreviewCell } from 'components/Table/Cell/AssetPreviewCell';
import { useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { caseStateTranslate } from 'utils/enum-translate';
import { formatBoolean, isNumericField } from 'utils/format/field';

interface CaseFormProps {
  targetCase?: CaseResponse;
  projectId?: string;
  allFields: FieldResponse[];
  allAssets: AssetResponse[];
  onSuccess?: () => void;
  onCancel?: () => void;
  inDialog?: boolean;
  isSingleCase?: boolean;
}

export const CaseForm = ({
  targetCase,
  projectId,
  allFields,
  allAssets,
  onSuccess,
  onCancel,
  inDialog,
  isSingleCase,
}: CaseFormProps) => {
  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateCase();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateCase();

  const { hasPermissions } = useAuth();

  const [selectedSuggestion, setSelectedSuggestion] =
    useState<AddressSuggestion>();

  const { register, control, setValue, handleSubmit, watch } = useForm<
    CaseCreateRequest | CaseUpdateRequest
  >({
    defaultValues: targetCase
      ? // Update
        {
          ...targetCase,
          caseFields: allFields
            .sort((e) => e.order)
            .map((e) => {
              const caseField = targetCase.caseFields.find(
                (cf) => cf.fieldId === e.id
              );
              return {
                fieldId: e.id,
                value:
                  e.type == EFieldType.Boolean
                    ? formatBoolean(caseField?.value || '')
                    : caseField
                      ? caseField.value
                      : '',
              };
            }),
          assetIds: targetCase?.assets.map((asset) => asset.id),
        }
      : // Create
        {
          caseFields: allFields
            .sort((e) => e.order)
            .map((field) => ({
              fieldId: field.id,
              value: field.type === EFieldType.Boolean ? 'Nej' : '',
            })),
          assetIds: [],
        },
  });

  const { fields } = useFieldArray({
    control,
    name: 'caseFields',
  });

  const onSubmit = handleSubmit(async (result) => {
    if (selectedSuggestion && selectedSuggestion.forslagstekst) {
      result.address = selectedSuggestion.forslagstekst;
    }

    if (targetCase) {
      // If single case, we have to force a maskId, as it's not shown in DOM, and thus [Required] in backend will fail
      if (isSingleCase || !hasPermissions([EPermission.ModuleBoligvaelger])) {
        result.maskId = targetCase.maskId || 'N/A';
      }
      await updateAsync({
        id: targetCase.id,
        caseUpdateRequest: result,
      });
    } else {
      if (!projectId) return;
      await createAsync({
        projectId,
        caseCreateRequest: result,
      });
    }
    onSuccess?.();
  });

  const onAssetsSelected = (assets: AssetResponse[] | null) => {
    const assetIds = assets?.map((asset) => asset.id) ?? [];
    setValue('assetIds', assetIds);
  };

  return (
    <form onSubmit={onSubmit} className="space-y-2">
      <AutocompleteAddress
        label={'Adresse'}
        readonlyInput={
          targetCase && !hasPermissions([EPermission.CaseModifyAddress])
        }
        suggestAddresses={true}
        registration={() => register('address', { required: true })}
        formWatch={watch('address') || ''}
        formSetValue={(value: string) => setValue('address', value)}
        onSuggestionSelectedFn={setSelectedSuggestion}
      />

      <div className="form-control">
        <label className="label">
          <span className="label-text">Status</span>
        </label>
        <select
          {...register('state', {
            required: true,
          })}
          className="select select-bordered"
        >
          {targetCase && !hasPermissions([EPermission.CaseModifyState])
            ? [targetCase.state]
            : [
                ECaseState.Available,
                ECaseState.Unavailable,
                ECaseState.Reserved,
                ECaseState.Deactivated,
              ].map((state) => (
                <option key={state} value={state}>
                  {caseStateTranslate(state)}
                </option>
              ))}
        </select>
      </div>

      {!isSingleCase ? (
        <PermissionProtectedComponent
          permissions={[EPermission.ModuleBoligvaelger]}
        >
          <div className="form-control">
            <label className="label">
              <span className="label-text">Maske ID i boligvælger</span>
            </label>
            <input
              {...register('maskId', {
                required: true,
              })}
              readOnly={
                targetCase && !hasPermissions([EPermission.CaseModifyMask])
              }
              type="text"
              className="input input-bordered"
            />
          </div>
        </PermissionProtectedComponent>
      ) : null}

      {!isSingleCase ? (
        <div className="form-control">
          <label className="label">
            <span className="label-text">Filer</span>
          </label>
          <EntitySelectMultiple<AssetResponse>
            data={allAssets}
            renderFormat={(asset) => (
              <div className="flex items-center justify-start space-x-2">
                <AssetPreviewCell asset={asset} />
                <p>{asset.originalFileName}</p>
              </div>
            )}
            onSelect={onAssetsSelected}
            initialValue={targetCase?.assets ?? []}
            searchPropertyKey="originalFileName"
            inDialog={inDialog}
            disabled={
              targetCase && !hasPermissions([EPermission.CaseModifyAssets])
            }
          />
          <input
            {...register('assetIds', { required: false })}
            type="text"
            className="input input-bordered hidden"
          />
        </div>
      ) : null}

      {fields.map((field, index) => (
        <div key={field.id} className="form-control">
          <label className="label">
            <span className="label-text">{allFields[index].name}</span>
          </label>
          {allFields[index].type === EFieldType.Longtext ||
          allFields[index].type.startsWith('LONGTEXT_') ? (
            <textarea
              {...register(`caseFields.${index}.value`)}
              readOnly={
                targetCase &&
                !hasPermissions([EPermission.CaseModifyCustomFields])
              }
              rows={8}
              className="textarea textarea-bordered"
            ></textarea>
          ) : allFields[index].type.includes('BOOLEAN') ? (
            <Controller
              key={index}
              name={`caseFields.${index}.value`}
              control={control}
              defaultValue="Nej" // default value
              render={({ field: { onChange, value } }) => (
                <input
                  readOnly={
                    targetCase &&
                    !hasPermissions([EPermission.CaseModifyCustomFields])
                  }
                  type="checkbox"
                  className="toggle toggle-primary"
                  checked={value === 'Ja'}
                  onChange={(e) => {
                    onChange(e.target.checked ? 'Ja' : 'Nej');
                    setValue(
                      `caseFields.${index}.value`,
                      e.target.checked ? 'Ja' : 'Nej'
                    );
                  }}
                />
              )}
            />
          ) : isNumericField(allFields[index]) ? (
            <input
              {...register(`caseFields.${index}.value`)}
              readOnly={
                targetCase &&
                !hasPermissions([EPermission.CaseModifyCustomFields])
              }
              type="number"
              min={0}
              max={1000000000}
              className="input input-bordered"
            />
          ) : (
            <input
              {...register(`caseFields.${index}.value`)}
              readOnly={
                targetCase &&
                !hasPermissions([EPermission.CaseModifyCustomFields])
              }
              type="text"
              className="input input-bordered"
            />
          )}
        </div>
      ))}

      <div className="flex justify-center space-x-4 pt-4">
        <button
          className="btn btn-primary"
          disabled={isPendingCreate || isPendingUpdate}
        >
          {targetCase ? 'Opdater' : 'Opret'}
        </button>
        {onCancel ? (
          <button type="button" className="btn" onClick={onCancel}>
            Annuller
          </button>
        ) : null}
      </div>
    </form>
  );
};
