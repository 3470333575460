/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ServiceTicketCreateRequest,
  ServiceTicketReplyRequest,
  ServiceTicketResponse,
  ServiceTicketResponsePagedData,
  ServiceTicketStateResponse,
} from '../models';
import {
    ServiceTicketCreateRequestFromJSON,
    ServiceTicketCreateRequestToJSON,
    ServiceTicketReplyRequestFromJSON,
    ServiceTicketReplyRequestToJSON,
    ServiceTicketResponseFromJSON,
    ServiceTicketResponseToJSON,
    ServiceTicketResponsePagedDataFromJSON,
    ServiceTicketResponsePagedDataToJSON,
    ServiceTicketStateResponseFromJSON,
    ServiceTicketStateResponseToJSON,
} from '../models';

export interface ServiceTicketsGetRequest {
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
    includeForAllOrganizations?: boolean;
}

export interface ServiceTicketsIdDeleteRequest {
    id: string;
}

export interface ServiceTicketsIdGetRequest {
    id: string;
}

export interface ServiceTicketsIdReplyPutRequest {
    id: string;
    serviceTicketReplyRequest?: ServiceTicketReplyRequest;
}

export interface ServiceTicketsPostRequest {
    serviceTicketCreateRequest?: ServiceTicketCreateRequest;
}

/**
 * 
 */
export class ServiceTicketsApi extends runtime.BaseAPI {

    /**
     */
    async serviceTicketsGetRaw(requestParameters: ServiceTicketsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceTicketResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        if (requestParameters.includeForAllOrganizations !== undefined) {
            queryParameters['includeForAllOrganizations'] = requestParameters.includeForAllOrganizations;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ServiceTickets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceTicketResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async serviceTicketsGet(requestParameters: ServiceTicketsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceTicketResponsePagedData> {
        const response = await this.serviceTicketsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTicketsIdDeleteRaw(requestParameters: ServiceTicketsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceTicketResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling serviceTicketsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ServiceTickets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceTicketResponseFromJSON(jsonValue));
    }

    /**
     */
    async serviceTicketsIdDelete(requestParameters: ServiceTicketsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceTicketResponse> {
        const response = await this.serviceTicketsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTicketsIdGetRaw(requestParameters: ServiceTicketsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceTicketResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling serviceTicketsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ServiceTickets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceTicketResponseFromJSON(jsonValue));
    }

    /**
     */
    async serviceTicketsIdGet(requestParameters: ServiceTicketsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceTicketResponse> {
        const response = await this.serviceTicketsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTicketsIdReplyPutRaw(requestParameters: ServiceTicketsIdReplyPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceTicketResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling serviceTicketsIdReplyPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ServiceTickets/{id}/reply`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceTicketReplyRequestToJSON(requestParameters.serviceTicketReplyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceTicketResponseFromJSON(jsonValue));
    }

    /**
     */
    async serviceTicketsIdReplyPut(requestParameters: ServiceTicketsIdReplyPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceTicketResponse> {
        const response = await this.serviceTicketsIdReplyPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTicketsPostRaw(requestParameters: ServiceTicketsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceTicketResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ServiceTickets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceTicketCreateRequestToJSON(requestParameters.serviceTicketCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceTicketResponseFromJSON(jsonValue));
    }

    /**
     */
    async serviceTicketsPost(requestParameters: ServiceTicketsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceTicketResponse> {
        const response = await this.serviceTicketsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTicketsStateGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceTicketStateResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ServiceTickets/State`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceTicketStateResponseFromJSON(jsonValue));
    }

    /**
     */
    async serviceTicketsStateGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceTicketStateResponse> {
        const response = await this.serviceTicketsStateGetRaw(initOverrides);
        return await response.value();
    }

}
