import {
  AssetSimpleResponse,
  EAssetType,
  EPosition,
  MediaSequenceAssetMutationCreateRequest,
  MediaSequenceAssetMutationResponse,
  MediaSequenceAssetMutationUpdateRequest,
  MediaSequenceAssetResponse,
} from 'api/core';
import { AssetUpload } from 'components/Form/AssetUpload';
import { ImageOverlayPreview } from 'components/Image/ImageOverlayPreview';
import { AssetSelectSingleAsync } from 'components/Select/AssetSelectSingleAsync';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { positionTranslate } from 'utils/enum-translate';

interface ImageOverlayProps {
  targetAssetMutation?: MediaSequenceAssetMutationResponse;
  asset: MediaSequenceAssetResponse;
  projectId?: string;
  inDialog?: boolean;
}

export const ImageOverlay = ({
  targetAssetMutation,
  asset,
  projectId,
  inDialog,
}: ImageOverlayProps) => {
  const {
    register,
    watch,
    setValue,
    formState: { disabled },
  } = useFormContext<
    | MediaSequenceAssetMutationCreateRequest
    | MediaSequenceAssetMutationUpdateRequest
  >();

  const [initialSelectedOverlayAsset, setInitialSelectedOverlayAsset] =
    useState<AssetSimpleResponse | undefined>(
      targetAssetMutation?.overlay?.asset
    );

  const overlayfileInputRef = useRef<HTMLInputElement>(null);

  const isCenteredOverlay =
    watch('overlay.position') === EPosition.TopCenter ||
    watch('overlay.position') === EPosition.BottomCenter;

  const isFullScreenOverlay =
    watch('overlay.position') === EPosition.FullScreen;

  // Seems to be needed..
  if (!watch('overlay.position'))
    setValue('overlay.position', EPosition.FullScreen);

  if (!watch('overlay.widthPercentage'))
    setValue('overlay.widthPercentage', 10);

  if (!watch('overlay.marginPercentageX'))
    setValue('overlay.marginPercentageX', 5);

  if (!watch('overlay.marginPercentageY'))
    setValue('overlay.marginPercentageY', 5);

  if (watch('overlay.delayInSeconds') === undefined)
    setValue('overlay.delayInSeconds', 0);

  if (!watch('overlay.durationInSeconds'))
    setValue('overlay.durationInSeconds', 30);

  useEffect(() => {
    if (isCenteredOverlay) {
      setValue('overlay.marginPercentageX', 0);
    }
  }, [isCenteredOverlay, setValue]);

  const onOverlayImageSelected = (asset: AssetSimpleResponse | null) => {
    if (!asset) return;
    setValue('overlay.assetId', asset.id, { shouldValidate: true });
    setInitialSelectedOverlayAsset(asset);
  };

  return (
    <>
      <AssetUpload
        fileInputRef={overlayfileInputRef}
        projectId={projectId}
        onAssetUploaded={onOverlayImageSelected}
        accept="image/*"
      />
      <div className="form-control">
        <label className="label">
          <span className="label-text">Billede</span>
        </label>
        <AssetSelectSingleAsync
          // Allow the user to seearch in all assets on organization (Not just project assets)
          // projectId={projectId}
          types={[EAssetType.Image]}
          onSelected={onOverlayImageSelected}
          initialValue={initialSelectedOverlayAsset}
          showCreateOption
          onCreateOptionSelected={() => overlayfileInputRef.current?.click()}
          inDialog={inDialog}
          disabled={disabled}
        />
        <input
          {...register('overlay.assetId', { required: true })}
          type="text"
          className="hidden"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Placering</span>
        </label>
        <select
          {...register('overlay.position', { required: true })}
          className="select select-bordered"
        >
          {[
            EPosition.FullScreen,
            EPosition.TopLeft,
            EPosition.TopCenter,
            EPosition.TopRight,
            EPosition.BottomLeft,
            EPosition.BottomCenter,
            EPosition.BottomRight,
          ].map((position) => (
            <option key={position} value={position}>
              {positionTranslate(position)}
            </option>
          ))}
        </select>
      </div>

      <div className={twMerge('form-control', isFullScreenOverlay && 'hidden')}>
        <label className="label">
          <span className="label-text">Bredde (%)</span>
        </label>
        <input
          {...register('overlay.widthPercentage', { required: true })}
          type="number"
          min={1}
          defaultValue={10}
          max={100}
          step={1}
          className="input input-bordered"
        />
      </div>

      {!isFullScreenOverlay ? (
        <ImageOverlayPreview
          asset={asset}
          overlayAsset={initialSelectedOverlayAsset}
          position={watch('overlay.position')}
          widthPercentage={watch('overlay.widthPercentage')}
          marginPercentageX={
            isCenteredOverlay ? 0 : watch('overlay.marginPercentageX')
          }
          marginPercentageY={watch('overlay.marginPercentageY')}
        />
      ) : null}

      <div className="form-control">
        <label className="label">
          <span className="label-text">Forsinkelse</span>
        </label>
        <input
          {...register('overlay.delayInSeconds', { required: true })}
          type="number"
          min={0}
          max={30}
          defaultValue={0}
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Varighed</span>
        </label>
        <input
          {...register('overlay.durationInSeconds', {
            required: true,
          })}
          type="number"
          min={1}
          max={30}
          step="any"
          defaultValue={4} /* Base on the duration of the asset */
          className="input input-bordered"
        />
      </div>

      {!isFullScreenOverlay ? (
        <div className="collapse collapse-arrow bg-base-200">
          <input type="checkbox" />
          <div className="collapse-title">Avancerede indstillinger</div>
          <div className="collapse-content">
            {!isCenteredOverlay ? (
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Forskydning på X-akse (%)</span>
                </label>
                <input
                  {...register('overlay.marginPercentageX', {
                    required: true,
                  })}
                  type="number"
                  min={0}
                  max={100}
                  step={1}
                  defaultValue={5}
                  className="input input-bordered"
                />
              </div>
            ) : null}

            <div className="form-control">
              <label className="label">
                <span className="label-text">Forskydning på Y-akse (%)</span>
              </label>
              <input
                {...register('overlay.marginPercentageY', {
                  required: true,
                })}
                type="number"
                min={0}
                max={100}
                step={1}
                defaultValue={5}
                className="input input-bordered"
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
