import { useEffect } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface VolumePickerProps {
  registerFn: () => UseFormRegisterReturn;
  watchFn: () => number;
  setFn: (value: number) => void;
  defaultVolume: number;
  min: number;
  max: number;
  step: number;
  showZeroStep: boolean;
  tickRenderer?: (value: number) => string | JSX.Element;
  showCurrentValueInTop: boolean;
}

export const VolumePicker = ({
  registerFn,
  watchFn,
  setFn,
  defaultVolume,
  min,
  max,
  step,
  showZeroStep,
  tickRenderer,
  showCurrentValueInTop,
}: VolumePickerProps) => {
  const volume = watchFn();

  useEffect(() => {
    if (volume > max) {
      setFn(max);
    } else if (volume < min) {
      setFn(min);
    }
  }, [volume, min, max, setFn]);

  const defaultTickRenderer = (value: number) => {
    const localMin = showZeroStep ? 0 : min;
    const percentage = Math.round(
      ((value - localMin) / (max - localMin)) * 100
    );
    const displayValue =
      value == 0 ? 'Lydløs' : value == max ? 'Max' : `${percentage}%`;
    const className =
      value != 0 && value == volume
        ? 'text-primary transition-colors duration-300 font-bold'
        : value != 0 && value <= volume
          ? 'text-primary transition-colors duration-300'
          : 'transition-colors duration-300';
    return <span className={className}>{displayValue}</span>;
  };

  return (
    <div className="form-control">
      <label className="label">
        <span className="label-text">Lydniveau</span>
      </label>
      <div className="relative w-full">
        <input
          {...registerFn?.()}
          defaultValue={defaultVolume}
          type="range"
          min={showZeroStep ? 0 : min}
          max={max}
          step={step}
          className="range range-primary transition-all duration-300"
          style={{
            background: `linear-gradient(to right, #4CAF50 ${((volume - min) / (max - min)) * 100}%, #D1D5DB ${((volume - min) / (max - min)) * 100}%)`,
            transition: 'background 0.3s ease',
          }}
        />
        {showCurrentValueInTop ? (
          <div className="absolute top-[-20px] left-1/2 transform -translate-x-1/2 text-xs font-bold text-primary transition-transform duration-300">
            {Math.round((volume - min) / step + 1)}/
            {Math.round((max - min) / step + 1)}
          </div>
        ) : null}
      </div>
      <div className="flex w-full justify-between px-2 text-xs mt-1 text-gray-400">
        {[
          ...Array(
            Math.round((max - min) / step + (showZeroStep ? 2 : 1))
          ).keys(),
        ].map((i) => (
          <div key={i} className="flex flex-col items-center w-1">
            <span
              className={`transition-colors duration-300 ${
                i - (showZeroStep ? 1 : 0) <= Math.round((volume - min) / step)
                  ? 'text-primary'
                  : ''
              }`}
            >
              |
            </span>
            <span className="mt-1">
              {tickRenderer
                ? tickRenderer(
                    parseFloat(((showZeroStep ? 0 : min) + i * step).toFixed(2))
                  )
                : defaultTickRenderer(
                    parseFloat(((showZeroStep ? 0 : min) + i * step).toFixed(2))
                  )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
