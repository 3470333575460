import { useGetAuditLogs } from 'api/useAuditLogs';
import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { AuditLogsTable } from './components/AuditLogsTable';

export const AuditLogsView = () => {
  const { state } = useTableQueryState();
  const { data, isFetching: isFetchingFields } = useGetAuditLogs(state);

  const onTestErrorClick = () => {
    if (window.confirm('Er du sikker på du vil sende en Sentry Test Error?')) {
      throw new Error('Sentry Test Error');
    }
  };

  return (
    <Page title="Audit Logs">
      <div className="flex justify-start py-4 w-full">
        <button className="btn btn-error" onClick={onTestErrorClick}>
          Sentry Test error
        </button>
      </div>
      <AuditLogsTable
        data={data}
        isFetching={isFetchingFields}
        queryState={state}
      />
    </Page>
  );
};
