import { useCreateTextEntriesUsingAi } from 'api/useTextCollectionsApi';
import {
  AddressSuggestion,
  AutocompleteAddress,
} from 'components/Form/AutocompleteAddress';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FullscreenLoadingWithText, InlineLoading } from 'components/Loading';
import { twMerge } from 'tailwind-merge';
import { EWritingStyle, EWritingTargetAudience } from 'api/core';
import {
  writingStyleTranslate,
  writingTargetAudienceTranslate,
} from 'utils/enum-translate';
import { AnimatedIconKey } from 'components/Icon/AnimatedIcon';

interface SmartTextFormProps {
  onSuccess?: (id: string) => void;
  onCancel?: () => void;
}

enum EStep {
  Address,
  SellingPoints,
  Preferences,
}

const translateStep = (step: EStep) => {
  switch (step) {
    case EStep.Address:
      return 'Adresse';
    case EStep.SellingPoints:
      return 'Købsargumenter';
    case EStep.Preferences:
      return 'Skrivestil';
    default:
      return 'Ukendt';
  }
};

export const SmartTextCreateForm = ({
  onSuccess,
  onCancel,
}: SmartTextFormProps) => {
  const MIN_SELLING_POINTS = 3;
  const MAX_SELLING_POINTS = 5;
  const STEPS = [EStep.Address, EStep.SellingPoints, EStep.Preferences];

  const { mutateAsync: createTextEntriesUsingAiAsync } =
    useCreateTextEntriesUsingAi();

  const [selectedSuggestion, setSelectedSuggestion] =
    useState<AddressSuggestion>();

  const [currentStep, setCurrentStep] = useState(STEPS[0]);

  const [sellingPoints, setSellingPoints] = useState<string[]>(
    new Array(MIN_SELLING_POINTS).fill('')
  );

  const [loadingMessage, setLoadingMessage] = useState<{
    message: string;
    icon: AnimatedIconKey;
  } | null>(null);
  const { register, handleSubmit, watch, setValue } = useForm<{
    address: string;
    writingStyle: EWritingStyle;
    writingTargetAudience: EWritingTargetAudience;
  }>({
    defaultValues: {
      address: '',
      writingStyle: EWritingStyle.Unspecified,
      writingTargetAudience: EWritingTargetAudience.Unspecified,
    },
  });

  const onSubmit = handleSubmit(async () => {
    if (!selectedSuggestion || !selectedSuggestion.data.id) return;

    try {
      setLoadingMessage({
        message: 'Udarbejder boligtekst...',
        icon: 'pencil-icon',
      });

      const textCollection = await createTextEntriesUsingAiAsync({
        textCollectionWithAiCreateRequest: {
          address: selectedSuggestion.forslagstekst,
          dawaAddressId: selectedSuggestion.data.id,
          sellingPoints: sellingPoints.filter((sp) => sp.trim()),
          writingStyle: watch('writingStyle'),
          writingTargetAudience: watch('writingTargetAudience'),
        },
      });

      setLoadingMessage(null);
      onSuccess?.(textCollection.id);
    } catch (error) {
      setLoadingMessage(null);
    }
  });

  return (
    <>
      {loadingMessage ? (
        <FullscreenLoadingWithText
          text={loadingMessage.message}
          icon={loadingMessage.icon}
        />
      ) : null}

      <form onSubmit={onSubmit} className="space-y-2">
        <div className="flex flex-col pt-2 gap-4">
          <ul className="steps steps-horizontal w-full">
            {STEPS.map((s, index) => (
              <li
                onClick={() => setCurrentStep(Math.min(currentStep, s))}
                id={`step-${index + 1}`}
                key={index}
                className={twMerge(
                  'step !min-w-32 pb-4 md:pb-0',
                  s == currentStep && 'font-bold',
                  s <= currentStep &&
                    'cursor-pointer step-primary after:!text-white hover:font-bold hover:step-info'
                )}
              >
                <span>{translateStep(s)}</span>
              </li>
            ))}
          </ul>
          <div className="divider"></div>
          {currentStep === EStep.Address ? (
            <>
              <AutocompleteAddress
                label={
                  <p>
                    Adresse <span className="text-red-500">*</span>
                  </p>
                }
                suggestAddresses={true}
                registration={() => register('address', { required: true })}
                formWatch={watch('address') || ''}
                formSetValue={(value: string) => setValue('address', value)}
                onSuggestionSelectedFn={setSelectedSuggestion}
              />
            </>
          ) : currentStep === EStep.SellingPoints ? (
            <>
              {sellingPoints.map((_, index) => (
                <div className="form-control" key={index}>
                  <label className="label" htmlFor={`selling-point-${index}`}>
                    <span className="label-text">
                      Købsargument {index + 1}{' '}
                    </span>
                  </label>
                  <input
                    key={index}
                    id={`selling-point-${index}`}
                    type="text"
                    className="input input-bordered"
                    placeholder={`Købsargument ${index + 1}`}
                    value={sellingPoints[index]}
                    onChange={(e) => {
                      const newSellingPoints = [...sellingPoints];
                      newSellingPoints[index] = e.target.value;
                      setSellingPoints(newSellingPoints);
                    }}
                  />
                </div>
              ))}

              {sellingPoints.length < MAX_SELLING_POINTS ? (
                <button
                  type="button"
                  className="btn btn-sm btn-outline w-fit"
                  disabled={
                    sellingPoints.length >= MAX_SELLING_POINTS ||
                    !!loadingMessage
                  }
                  onClick={() => {
                    setSellingPoints([...sellingPoints, '']);
                  }}
                >
                  Tilføj endnu et købsargument
                </button>
              ) : null}
            </>
          ) : currentStep === EStep.Preferences ? (
            <>
              <div className="form-control">
                <label className="label" htmlFor="writing-style">
                  <span className="label-text">Skrivestil</span>
                </label>
                <select
                  {...register('writingStyle')}
                  id="writing-style"
                  className="select input-bordered"
                  disabled={!!loadingMessage}
                >
                  {Object.values(EWritingStyle).map((ws) => (
                    <option key={ws} value={ws}>
                      {writingStyleTranslate(ws)}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-control">
                <label className="label" htmlFor="target-audience">
                  <span className="label-text">Målgruppe</span>
                </label>
                <select
                  {...register('writingTargetAudience')}
                  id="target-group"
                  className="select input-bordered"
                  disabled={!!loadingMessage}
                >
                  {Object.values(EWritingTargetAudience).map((ws) => (
                    <option key={ws} value={ws}>
                      {writingTargetAudienceTranslate(ws)}
                    </option>
                  ))}
                </select>
              </div>
            </>
          ) : null}
        </div>

        {
          // TODO: Remove when loading indicator supports being on top of modals
        }
        {loadingMessage ? (
          <InlineLoading
            text={loadingMessage.message}
            icon={loadingMessage.icon}
          />
        ) : null}

        <div className="flex justify-center space-x-4 pt-4">
          {currentStep !== STEPS[STEPS.length - 1] ? (
            <button
              className="btn btn-primary"
              disabled={
                !!loadingMessage ||
                (currentStep === EStep.Address && !selectedSuggestion) ||
                (currentStep === EStep.SellingPoints &&
                  sellingPoints.filter((sp) => sp?.trim()).length <
                    MIN_SELLING_POINTS)
              }
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setCurrentStep((e) => e + 1);
              }}
            >
              Fortsæt
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!!loadingMessage}
            >
              Lav boligtekst
            </button>
          )}

          {onCancel && !loadingMessage ? (
            <button type="button" className="btn" onClick={onCancel}>
              Annuller
            </button>
          ) : null}
        </div>
      </form>
    </>
  );
};
