/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EAssetMetadata = {
    MediaDuration: 'MediaDuration'
} as const;
export type EAssetMetadata = typeof EAssetMetadata[keyof typeof EAssetMetadata];


export function EAssetMetadataFromJSON(json: any): EAssetMetadata {
    return EAssetMetadataFromJSONTyped(json, false);
}

export function EAssetMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EAssetMetadata {
    return json as EAssetMetadata;
}

export function EAssetMetadataToJSON(value?: EAssetMetadata | null): any {
    return value as any;
}

