import { OrganizationSimpleResponse } from 'api/core';
import { useGetJoinableOrganizations } from 'api/useOrganizationsApi';
import { useAuth } from 'auth/AuthProvider';
import { buildQueryState } from 'components/Table/useTableQueryState';
import { Tooltip } from 'components/Tooltip';
import { Menu } from 'lucide-react';
import { JoinOrganization } from 'pages/Administration/components/Organization/JoinOrganization';
import { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

interface SidebarProps {
  toggleSidebar: () => void;
}

export const Header = ({ toggleSidebar }: SidebarProps) => {
  const { user, signOut } = useAuth();

  const { data: joinableOrganizations } = useGetJoinableOrganizations(
    buildQueryState<OrganizationSimpleResponse>({
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    })
  );

  const [canChangeOrganization, setCanChangeOrganization] =
    useState<boolean>(false);

  useEffect(() => {
    setCanChangeOrganization(
      !!user && !!joinableOrganizations && joinableOrganizations.data.length > 1
    );
  }, [user, joinableOrganizations]);

  return (
    <nav className="fixed top-0 z-50 w-full bg-base-200">
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <label
              tabIndex={0}
              className="btn btn-ghost md:hidden"
              onClick={toggleSidebar}
            >
              <Menu className="swap-on fill-current w-8" />
            </label>
          </div>
          <div className="flex items-center justify-center sm:justify-start flex-grow">
            <Link className="flex md:ml-2 md:mr-24" to="">
              <img
                src={user?.organization?.logoUrl ?? '/logo-icons.png'}
                className="h-12 md:mr-3"
                alt="Openomic"
              />
              <span className="self-center text-primary text-xl font-semibold sm:text-2xl whitespace-nowrap hidden sm:block">
                Smart Create
              </span>
            </Link>
          </div>
          <div className="flex items-center">
            {canChangeOrganization ? (
              <div className="hidden sm:block">
                <JoinOrganization
                  trigger={
                    <Tooltip tooltip="Skift butik" position="bottom">
                      <span className="text-primary font-semibold whitespace-nowrap mr-2 cursor-pointer">
                        {user?.organization.name}
                        <span className="text-base inline-block mx-1">›</span>
                      </span>
                    </Tooltip>
                  }
                />
              </div>
            ) : null}
            <div className="dropdown dropdown-end">
              <label
                tabIndex={0}
                className="btn btn-outline btn-circle avatar online placeholder:"
              >
                <div className="w-8 rounded-full">
                  <span className="text-xl">{user?.name?.slice(0, 2)}</span>
                </div>
              </label>
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-white rounded-box w-52"
              >
                <li>
                  <NavLink to="/profile" className="justify-between">
                    Profil
                    <span className="badge">Ny</span>
                  </NavLink>
                </li>
                {canChangeOrganization ? (
                  <li>
                    <JoinOrganization trigger={<p>Skift butik</p>} />
                  </li>
                ) : null}
                <li>
                  <a onClick={signOut}>Log ud</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
