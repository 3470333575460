import { Page } from 'layouts/Admin/Page';
import { useAuth } from 'auth/AuthProvider';
import { FacebookLogin } from 'pages/Publications/FacebookLogin';

export const OrganizationSocialMedia = () => {
  const { user } = useAuth();
  if (!user) return null;

  return (
    <Page
      title={user.organization.name}
      description={`Denne side viser alle sociale medier der er tilknyttet ${user.organization.name}.`}
      breadcrumbs={[
        { name: user.organization.name },
        { name: 'Sociale Medier' },
      ]}
    >
      <FacebookLogin />
    </Page>
  );
};
