import { Outlet } from 'react-router-dom';
import { useDocumentTitle } from 'utils/useDocumentTitle';
import { AnonymousProtectedRoute } from 'auth/AnonymousProtectedRoute';

export const LoginLayout = () => {
  useDocumentTitle('Login');
  return (
    <AnonymousProtectedRoute>
      <div
        className="relative flex flex-col items-center justify-center h-screen overflow-hidden"
        style={{
          backgroundImage: 'linear-gradient(to top, #09203f 0%, #537895 100%)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="w-full p-6 bg-base-200 rounded-md shadow-md border-top lg:max-w-lg">
          {/* <div className="flex justify-end">
            <ThemeSwapper />
          </div> */}
          <img
            src="/logo-icon.png"
            className="mx-auto mb-4 h-16"
            alt="Openomic"
          />
          <h1 className="text-3xl font-semibold text-center">Smart Create</h1>
          <Outlet />
        </div>
      </div>
    </AnonymousProtectedRoute>
  );
};
