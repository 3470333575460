import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  AssetSimpleResponse,
  EAssetMetadata,
  EAssetSource,
  EAssetType,
  EEntityState,
  MediaSequenceAssetMutationCreateRequest,
  MediaSequenceAssetMutationResponse,
  MediaSequenceAssetMutationUpdateRequest,
} from 'api/core';
import { useFormContext } from 'react-hook-form';
import { Id, toast } from 'react-toastify';
import { formatTime } from 'utils/format/time';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import {
  useBeginCreateAsset,
  useFinalizeCreateAsset,
  useUploadPresignedFileUrl,
} from 'api/useAssetsApi';
import {
  calculateChecksumAsync,
  clearWaveform,
  drawWaveformAsync,
  getAudioDurationAsync,
  getBlobFromUrlAsync,
  getDrawRealTimeWaveformFn,
} from 'utils/file-util';
import { PauseIcon, PlayIcon, Trash2Icon } from 'lucide-react';
import { VolumePicker } from 'components/Form/VolumePicker';

const DEFAULT_MAX_RECORD_DURATION = 5;
const DEFAULT_AUDIO_MIME_TYPE = 'audio/webm';
const BIT_RATE_PER_SECOND = 320000;

interface VoiceoverProps {
  targetAssetMutation?: MediaSequenceAssetMutationResponse;
  projectId?: string;
  asset: AssetSimpleResponse;
  imageToVideoMutation?: MediaSequenceAssetMutationResponse;
  inDialog?: boolean;
  setPreOnSubmitFn: Dispatch<
    SetStateAction<
      (
        req:
          | MediaSequenceAssetMutationCreateRequest
          | MediaSequenceAssetMutationUpdateRequest
      ) => Promise<boolean>
    >
  >;
}

export const Voiceover = ({
  targetAssetMutation,
  projectId,
  asset,
  imageToVideoMutation,
  setPreOnSubmitFn,
}: VoiceoverProps) => {
  const {
    register,
    setValue,
    watch,
    setError,
    clearErrors,
    trigger,
    formState: { disabled },
  } = useFormContext<
    | MediaSequenceAssetMutationCreateRequest
    | MediaSequenceAssetMutationUpdateRequest
  >();

  const { mutateAsync: createAssetAsync } = useBeginCreateAsset();
  const { mutateAsync: uploadFileAsync } = useUploadPresignedFileUrl();
  const { mutateAsync: markAsync } = useFinalizeCreateAsset();

  const progressBarRef = useRef<HTMLDivElement>(null);
  const audioPlaybackRef = useRef<HTMLAudioElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const [maxRecordDuration, setMaxRecordDuration] = useState<number>(
    DEFAULT_MAX_RECORD_DURATION
  );
  const [currentRecordTime, setCurrentRecordTime] = useState<number>(0);
  const [currentPlayTime, setCurrentPlayTime] = useState<number>(0);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const recordingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);

  const [initialSelectedVoiceoverAsset] = useState<
    AssetSimpleResponse | undefined
  >(targetAssetMutation?.voiceover?.asset);

  useEffect(() => {
    setMaxRecordDuration(
      imageToVideoMutation?.imageToVideo?.durationInSeconds ||
        DEFAULT_MAX_RECORD_DURATION
    );
  }, [imageToVideoMutation]);

  // On unmount. Clear errors, so we don't block other mutations
  useEffect(() => {
    return () => {
      clearErrors('voiceover.assetId');
      setPreOnSubmitFn(() => async () => true);
    };
  }, [clearErrors, setPreOnSubmitFn]);

  // On mount. If has existing voiceover, set assetId and fetch audio
  useEffect(() => {
    if (initialSelectedVoiceoverAsset && setValue) {
      setValue('voiceover.assetId', initialSelectedVoiceoverAsset.id, {
        shouldValidate: true,
      });

      getBlobFromUrlAsync(initialSelectedVoiceoverAsset.url)
        .then((blob) => {
          setRecordedChunks([blob]);
        })
        .catch((error) => {
          console.error(error);
          toast.error('Kunne ikke hente lydfil');
        });

      getAudioDurationAsync(initialSelectedVoiceoverAsset.url)
        .then((duration) => {
          setCurrentRecordTime(duration);
        })
        .catch((error) => {
          console.error(error);
          toast.error('Kunne ikke hente lydfilens varighed');
        });
    }
  }, [initialSelectedVoiceoverAsset, setValue]);

  // On mount. Set preOnSubmitFn to check if we have a voiceover
  useEffect(() => {
    setPreOnSubmitFn(
      () =>
        async (
          req:
            | MediaSequenceAssetMutationCreateRequest
            | MediaSequenceAssetMutationUpdateRequest
        ) => {
          if (!req.voiceover) {
            console.warn('No voiceover');
            return false;
          }

          // Should never happen, form should be invalud
          if (!req.voiceover.assetId && !audioBlob) {
            console.warn('No voiceover assetId or audioBlob');
            return false;
          }

          // If we have an assetId, we don't need to upload the blob
          if (req.voiceover.assetId) {
            return true;
          }

          // We must upload the blob
          if (!req.voiceover.assetId && audioBlob) {
            let fileExtension = audioBlob?.type.split('/')[1];
            if (!fileExtension) fileExtension = 'webm';
            if (fileExtension.includes(';'))
              fileExtension = fileExtension.split(';')[0];

            const fileName = `voiceover-${new Date().toLocaleString('da-DK').replace(/:/g, '-').replace(/ /g, '_')}-${asset.originalFileName}.${fileExtension}`;

            const sha256 = await calculateChecksumAsync(audioBlob);
            const assetResponse = await createAssetAsync({
              assetCreateRequest: {
                projectId,
                entityState: EEntityState.Ghost,
                fileName: fileName,
                mimeType: audioBlob.type,
                fileSize: audioBlob.size,
                checksumSha256: sha256,
                source: EAssetSource.UserUploaded,
                metadata: [
                  {
                    key: EAssetMetadata.MediaDuration,
                    value: currentRecordTime.toString(),
                  },
                ],
              },
            });

            if (assetResponse.foundMatchingAsset) {
              req.voiceover.assetId = assetResponse.id;
              return true;
            }

            await uploadFileAsync({
              url: assetResponse.presignedUploadUrl,
              file: audioBlob,
            });

            const result = await markAsync({ id: assetResponse.id });
            req.voiceover.assetId = result.id;
            return true;
          }
          return false;
        }
    );
  }, [
    setPreOnSubmitFn,
    audioBlob,
    createAssetAsync,
    currentRecordTime,
    markAsync,
    uploadFileAsync,
    projectId,
    asset,
  ]);

  const volume = watch('voiceover.volume');
  useEffect(() => {
    if (volume && audioPlaybackRef.current) {
      audioPlaybackRef.current.volume = volume;
    }
  }, [volume]);

  const getProgressPosition = (time: number) => {
    const percentage = (time / maxRecordDuration) * 100;
    return `${percentage}%`;
  };

  const startRecording = async () => {
    setIsPlaying(false);
    setCurrentPlayTime(0);
    setValue('voiceover.assetId', '', { shouldValidate: true });
    let stream: MediaStream;

    let promptId: Id | undefined;
    try {
      let hasAccess = false;
      setTimeout(() => {
        if (hasAccess) return;
        promptId = toast.info(
          'Klik på "Tillad" for at give adgang til din mikrofon.'
        );
      }, 200);
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      hasAccess = true;

      if (promptId) {
        toast.dismiss(promptId);
        toast.success('Du har givet adgang til mikrofonen');
      }
    } catch (error) {
      toast.error('Kunne ikke få adgang til mikrofonen', {
        toastId: 'microphone-access-error',
      });
      console.error(error);
      return;
    }

    setIsRecording(true);

    // Create a new MediaRecorder instance
    const recorder = new MediaRecorder(stream, {
      audioBitsPerSecond: BIT_RATE_PER_SECOND,
    });

    recorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        setRecordedChunks((prev) => [...prev, event.data]);
      }
    };

    recorder.start();
    setMediaRecorder(recorder);
    mediaStreamRef.current = stream;

    const drawRealTimeWaveform = getDrawRealTimeWaveformFn(stream, canvasRef);

    const step = 0.01;
    recordingIntervalRef.current = setInterval(() => {
      setCurrentRecordTime((prevTime) => {
        const newPrevTime = prevTime + step;
        if (newPrevTime >= maxRecordDuration) {
          stopRecording();
        } else {
          drawRealTimeWaveform();
        }
        return Math.min(newPrevTime, maxRecordDuration);
      });
    }, 1000 * step);
  };

  const stopRecording = useCallback(() => {
    if (recordingIntervalRef.current) {
      clearInterval(recordingIntervalRef.current);
      recordingIntervalRef.current = null;
    }
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.requestData(); // Request the final data chunk
      mediaRecorder.stop();
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    setIsRecording(false);
  }, [mediaRecorder, recordingIntervalRef, mediaStreamRef]);

  // Check when blob changes
  useEffect(() => {
    if (!recordedChunks.length) {
      setAudioUrl(null);
      setAudioBlob(null);
      return;
    }

    const audioBlob = new Blob(recordedChunks, {
      type: recordedChunks[0]?.type || DEFAULT_AUDIO_MIME_TYPE,
    });

    const newAudioUrl = URL.createObjectURL(audioBlob);
    setAudioUrl(newAudioUrl);
    setAudioBlob(audioBlob);
  }, [recordedChunks]);

  // When audio blob changes, we draw the waveform
  useEffect(() => {
    if (audioBlob && canvasRef.current) {
      drawWaveformAsync(audioBlob, canvasRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioBlob, canvasRef.current]);

  // When audio url changes
  useEffect(() => {
    if (!audioUrl) {
      if (canvasRef.current) clearWaveform(canvasRef.current);
      setError('voiceover.assetId', {
        message: `Der mangler en lydfil`,
      });
      return;
    }
    if (audioUrl) {
      clearErrors('voiceover.assetId');
    }

    trigger('voiceover.assetId');
  }, [audioUrl, setError, clearErrors, trigger]);

  return (
    <>
      {/* Show progress bar for recording, i.e current time */}
      {currentRecordTime > 0 ? (
        <div
          className="relative w-full h-12 bg-gray-300 rounded-lg"
          ref={progressBarRef}
        >
          {/* Canvas for waveform */}
          <canvas
            ref={canvasRef}
            className="absolute top-0 left-0 h-full bg-gray-200"
            style={{ width: getProgressPosition(currentRecordTime) }}
          />

          {isRecording || currentRecordTime > 0 ? (
            <>
              {/* Record Playhead indicates current time of recording */}
              <div
                className="absolute bg-white h-[125%] rounded-lg w-1 opacity-90 pointer-events-none top-[-0.25rem]"
                style={{ left: getProgressPosition(currentRecordTime) }}
              ></div>

              {/* Current record tiem shown above record playhead */}
              <div
                className="absolute bg-white rounded-lg w-16 h-6 text-xs text-gray-600 flex justify-center items-center pointer-events-none border-4 border-gray-300"
                style={{
                  left: `calc(${getProgressPosition(currentRecordTime)} - 2rem)`,
                  top: '-1.75rem',
                }}
              >
                {formatTime(currentRecordTime)}
              </div>
            </>
          ) : null}

          {currentPlayTime ? (
            <>
              {/* Playback Playhead indicates current time of playback */}
              <div
                className="absolute bg-white h-[125%] rounded-lg w-1 opacity-90 pointer-events-none top-[-0.25rem]"
                style={{ left: getProgressPosition(currentPlayTime) }}
              ></div>

              {/* Current play time shown above playback playhead */}
              <div
                className="absolute bg-white rounded-lg w-16 h-6 text-xs text-gray-600 flex justify-center items-center pointer-events-none border-4 border-gray-300"
                style={{
                  left: `calc(${getProgressPosition(currentPlayTime)} - 2rem)`,
                  top: '-1.75rem',
                }}
              >
                {formatTime(currentPlayTime)}
              </div>
            </>
          ) : null}
        </div>
      ) : null}

      {/* Show recording controls here  */}
      <div className="flex justify-center space-x-2">
        {!isRecording && !isPlaying && audioUrl ? (
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => audioPlaybackRef.current?.play()}
          >
            <PlayIcon className="w-5 h-5 text-white" />
          </button>
        ) : null}

        {!isRecording && isPlaying ? (
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => audioPlaybackRef.current?.pause()}
          >
            <PauseIcon className="w-5 h-5 text-white" />
          </button>
        ) : null}

        {!isRecording && currentRecordTime === 0 ? (
          <button
            className="btn btn-neutral"
            type="button"
            disabled={disabled}
            onClick={startRecording}
          >
            <AnimatedIcon
              className="w-8 h-8"
              icon="record-icon"
              autoPlay={false}
              playOnHover={false}
              loop={false}
            />
          </button>
        ) : null}

        {isRecording ? (
          <button
            className="btn btn-neutral"
            type="button"
            disabled={disabled}
            onClick={stopRecording}
          >
            <AnimatedIcon
              className="w-8 h-8"
              icon="record-icon"
              autoPlay={true}
              playOnHover={false}
              loop={true}
            />
          </button>
        ) : null}

        {currentRecordTime > 0 && !isRecording ? (
          <button
            className="btn btn-error"
            onClick={() => {
              setAudioUrl(null);
              setRecordedChunks([]);
              setCurrentRecordTime(0);
              setCurrentPlayTime(0);
              setIsPlaying(false);
              setIsRecording(false);
            }}
          >
            <Trash2Icon className="w-5 h-5 text-white" />
          </button>
        ) : null}
      </div>

      {/* Playback of the recorded audio */}
      {audioUrl && (
        <audio
          src={audioUrl}
          crossOrigin="anonymous"
          ref={audioPlaybackRef}
          className="hidden"
          loop
          onTimeUpdate={() =>
            setCurrentPlayTime(audioPlaybackRef.current?.currentTime || 0)
          }
          onPlay={() => {
            setIsPlaying(true);
            if (audioPlaybackRef?.current) {
              audioPlaybackRef.current.volume = volume;
            }
          }}
          onPause={() => setIsPlaying(false)}
        />
      )}

      <VolumePicker
        registerFn={() => register('voiceover.volume', { required: true })}
        watchFn={() => watch('voiceover.volume')}
        setFn={(value) => setValue('voiceover.volume', value)}
        defaultVolume={0.5}
        min={0.1}
        max={1}
        step={0.1}
        showZeroStep={true}
        showCurrentValueInTop={false}
      />

      {asset.type === EAssetType.Image ? (
        <img
          className="w-full max-h-96 object-cover pt-2"
          src={asset.previewUrl || asset.url}
          alt={asset.originalFileName}
        />
      ) : null}
    </>
  );
};
