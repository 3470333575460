import { ColumnDef } from '@tanstack/react-table';

import { CustomTable } from 'components/Table/CustomTable';
import { ActionCell } from 'components/Table/Cell/ActionCell';
import { DateTimeCell } from 'components/Table/Cell/DateTimeCell';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { TableQueryState } from 'components/Table/useTableQueryState';

import { useMemo, useState } from 'react';

import { DeleteEntityById } from 'components/Form/DeleteEntityById';

import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import {
  DynamicTemplateResponse,
  DynamicTemplateResponsePagedData,
  EPermission,
} from 'api/core';
import {
  useDeleteDynamicTemplate,
  useGetDynamicTemplate,
} from 'api/useDynamicTemplatesApi';
import { DynamicTemplateUpdate } from './DynamicTemplateUpdate';
import { BooleanCell } from 'components/Table/Cell/BooleanCell';

interface TableProps {
  data?: DynamicTemplateResponsePagedData;
  isFetching: boolean;
  queryState: TableQueryState;
}

export const DynamicTemplatesTable = ({
  data,
  isFetching,
  queryState,
}: TableProps) => {
  const [editTarget, setEditTarget] = useState<DynamicTemplateResponse | null>(
    null
  );
  const [deleteTarget, setDeleteTarget] =
    useState<DynamicTemplateResponse | null>(null);

  const columns = useMemo(() => {
    const baseColumns: ColumnDef<DynamicTemplateResponse>[] = [
      {
        accessorKey: 'name',
        header: 'Navn',
      },
      {
        accessorKey: 'organizationIds',
        header: 'Organisationer',
        cell: (e) => (
          <div className="flex flex-col">
            {e.row.original.organizationIds.map((id) => (
              <span key={id} className="text-sm">
                {id}
              </span>
            ))}
          </div>
        ),
      },
      {
        accessorKey: 'isGlobal',
        header: 'Global',
        cell: (e) => <BooleanCell value={e.row.original.isGlobal} />,
      },
      {
        accessorKey: 'lastModifiedUtc',
        header: 'Sidst redigeret',
        cell: (e) => <DateTimeCell value={e.row.original.lastModifiedUtc} />,
      },
      {
        accessorKey: 'createdUtc',
        header: 'Oprettet',
        cell: (e) => <DateTimeCell value={e.row.original.createdUtc} />,
      },
      {
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) => (
          <div className="flex space-x-5 md:space-x-3">
            <ActionCell
              permissions={[EPermission.DynamicTemplateWrite]}
              icon={
                <AnimatedIcon
                  icon="pencil-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setEditTarget(e.row.original)}
            />
            <ActionCell
              permissions={[EPermission.DynamicTemplateDelete]}
              icon={
                <AnimatedIcon
                  icon="trash-icon"
                  className="h-8 md:h-6 w-8 md:w-6"
                />
              }
              onClick={() => setDeleteTarget(e.row.original)}
            />
          </div>
        ),
      },
    ];
    return baseColumns;
  }, [setEditTarget, setDeleteTarget]);

  const table = useGetCustomReactTable(
    data?.data ?? [],
    data?.page.totalPages ?? -1,
    columns,
    queryState
  );

  return (
    <>
      <CustomTable table={table} isLoading={isFetching} />
      {editTarget ? (
        <DynamicTemplateUpdate
          id={editTarget?.id}
          isInitialOpen={true}
          onClosed={() => setEditTarget(null)}
        />
      ) : null}
      {deleteTarget ? (
        <DeleteEntityById
          id={deleteTarget?.id}
          isInitialOpen={true}
          onClosed={() => setDeleteTarget(null)}
          titlePropertyKey={'name'}
          getter={useGetDynamicTemplate}
          deleter={useDeleteDynamicTemplate}
        />
      ) : null}
    </>
  );
};
