import { ColumnDef } from '@tanstack/react-table';
import { CustomTable } from 'components/Table/CustomTable';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';

export interface Translation {
  term: string;
  value: string;
  originalValue: string;
  fallback: string;
  multilineSupport: boolean;
}

interface TableProps {
  data: Translation[];
  isFetching: boolean;
}

const columns: ColumnDef<Translation>[] = [
  {
    accessorKey: 'term',
    header: 'Term',
    enableSorting: false,
  },
  {
    accessorKey: 'fallback',
    header: 'Fallback',
    enableSorting: false,
  },
  {
    accessorKey: 'value',
    header: 'Translation',
    enableSorting: false,
  },
];

export const TermsTable = ({ data, isFetching }: TableProps) => {
  const table = useGetCustomReactTable(data ?? [], 1, columns);

  return <CustomTable table={table} isLoading={isFetching} hidePagination />;
};
